import React from 'react';
import { useIntl } from "react-intl";
import Footer from "../components/Footer";
import Subscribe from "../components/Subscribe";
import Hero from "../components/Hero";
import Nav from "../components/Nav";
import Social from "../components/Social";
import withStyles from '@mui/styles/withStyles';
import {generateCanonical, generateLangLinks} from "../utils/langUtils";
import { NextSeo } from 'next-seo';
import { withRouter } from "next/router";
import RifugiList from "../components/RifugiList";  
import useSwr from 'swr'
import dynamic from 'next/dynamic'

const styles = (theme) => ({
  mainContainer: {
    position: "relative",
  },
});

const fetcher = (url) => fetch(url).then((res) => res.json())

function Index({ classes, router, rifugi, mapbox_key }) {
  const intl = useIntl();
  const BaseURL = "";
  const Description = intl.formatMessage({id: "index.description", defaultMessage: "I Rifugi di Monte Amiata"});
  const { data, error } = useSwr('/api/rifugi', fetcher);

  return (
    <div>
      <NextSeo
        title = {intl.formatMessage({id: "index.title", defaultMessage: "Rigufi di Amaiata"})}
        canonical = {generateCanonical(BaseURL, router.locale)}
        description = {Description}
        openGraph = {{
          description: Description,
        }}
        languageAlternates = {generateLangLinks(BaseURL)}
      />   
      <Nav />
      <div className={classes.mainContainer}>
        { data && (
          <>
            <Hero rifugi={data['Rifugi']}/>
            <RifugiList rifugi={data['Rifugi']} mapbox_key={mapbox_key} />
            <Subscribe />
            <Social />
            <Footer />
          </>
        )}
      </div>
    </div>
  );
};

export async function getServerSideProps(context) {
  return { props: { mapbox_key: process.env.MAPBOX_ACCESS_KEY } };
}

export default withStyles(styles)(withRouter(Index));