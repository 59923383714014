import { FormattedMessage } from "react-intl";
import Typography from "@mui/material/Typography";
import styles from "./styles";
import withStyles from '@mui/styles/withStyles';
import Link from "next/link";
import Image from "next/image";
import heroImage from '../../../public/images/amiata-hero.jpg';
import Button from "@mui/material/Button";

const Hero = ({ classes, rifugi, children }) => {
  var dbCount = rifugi ? rifugi.length: "...";
  dbCount = dbCount != 0 ? dbCount : "...";
  return (
    <div className={classes.hero} >
      <div className={classes.container}>
        <div className={classes.heading}>
          <Image
            fill
            src={heroImage}
            alt="An image of Monte Amiata"
            className={classes.backgroundImage}
          />
          <Typography
            variant="h4"
            color="inherit"
            component="h1"
            id="hero-heading"
            className={classes.title}
          >
            <FormattedMessage
              id="hero.heading"
              defaultMessage="Rifugi Naturalistici Amiata"
            />
          </Typography>
          <Typography
            color="inherit"
            className={classes.intro}
            variant="body2"
            component="h2"
            gutterBottom={true}
          >
            <FormattedMessage
              id="hero.headerText1"
              defaultMessage="L'Amiata, Montagna Madre nel cuore della Toscana, è una delle zone naturali più integre della regione. Come altri territori intatti, anch’essa è minacciata dallo sfruttamento indiscriminato delle sue risorse. Aderendo alla rete dei Rifugi Naturalistici possiamo fare la nostra parte per contribuire a difendere il territorio amiatino."
            />
          </Typography>         
          <Typography
            color="inherit"
            className={classes.intro}
            variant="body2"
            component="h2"
            gutterBottom={true}
          >
            <FormattedMessage
              id="hero.headerText2"
              defaultMessage="Anche tu puoi aderire al progetto Rifugi Naturalistici per salvaguardare il terreno che custodisci."
            />
          </Typography>          
          <Link href="/about" passHref >
            <Button
              variant="contained"
              href="/about"
              color="primary"
              type="submit"
              className={classes.CTAButton}
            >
              <FormattedMessage id="hero.readMoreButton" defaultMessage="Scopri come" />
            </Button> 
          </Link>                   
          <Link href="/register" passHref >
            <Button
              variant="contained"
              color="primary"
              type="submit"
              className={classes.CTAButton}
            >
              <FormattedMessage id="hero.registerButton" defaultMessage="Registra un Rifugio" />
            </Button> 
          </Link>
          {children}
        </div>
      </div>
    </div>
  );
};
export default withStyles(styles)(Hero);
