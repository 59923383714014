import { FormattedMessage, injectIntl } from "react-intl";
import withStyles from '@mui/styles/withStyles';
import Typography from "@mui/material/Typography";
import mailtoLink from "mailto-link";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  WhatsappIcon,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  EmailIcon
} from "react-share";
import classNames from "classnames";
import tracking from "../../utils/tracking";

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    padding: "50px",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    textAlign: "center",
    alignItems: "baseline",
    ...theme,
  },

  offset: {
    paddingTop: "240px",
    marginTop: -170,
  },

  offsetHome: {
    paddingTop: "50px",
    marginTop: -10,
  },

  shareHeading: {
    color: "white",
    marginBottom: "30px",
    flex: "1 0 100%",
    fontWeight: "bold",
  },

  ssbtn:  {
    margin: "0 20px",
    [theme.breakpoints.down("sm")]: {
      margin: "0 15px",
    },      
  },
});

const Social = ({
  classes,
  intl,
  sourcePage = "homepage" /* default value */,
  style,
}) => {

  var rootClassName = (sourcePage === "homepage") ? classes.offsetHome : classes.offset;

  const emailSubject = intl.formatMessage({
    id: "social.emailSubject",
    defaultMessage:
      "Rifugi Naturalistici Amiata",
  });
  const emailBody = intl.formatMessage({
    id: "social.emailBody",
    defaultMessage:
      "Sul rifugiamiata.it, anche tu puoi aderire al progetto Rifugi Naturalistici per salvaguardare il terreno che custodisci.",
  });
  const twitterTitle = intl.formatMessage({
    id: "social.twitterTitle",
    defaultMessage:
      "Sul rifugiamiata.it, anche tu puoi aderire al progetto Rifugi Naturalistici per salvaguardare il terreno che custodisci.",
  });
  const facebookQuote = intl.formatMessage({
    id: "social.facebookQuote",
    defaultMessage:
      "Sul rifugiamiata.it, anche tu puoi aderire al progetto Rifugi Naturalistici per salvaguardare il terreno che custodisci.",
  });
  const whatsAppTitle = intl.formatMessage({
    id: "social.whatsapp",
    defaultMessage:
      "Sul rifugiamiata.it, anche tu puoi aderire al progetto Rifugi Naturalistici per salvaguardare il terreno che custodisci.",
  });
  const emailLink = mailtoLink({ subject: emailSubject, body: emailBody });

  const handleEmailClick = (e) => {
    e.preventDefault();
    window.open(emailLink);
  };

  const shareButtonProps = {
    className: "ss-btn",
  };

  const trackShare = (network) => {
    tracking.trackSocialShare(network, sourcePage);
  };

  const trackWebExtension = (brower) => {
    tracking.trackWebExtension(brower, sourcePage);
  };
  return (
    <div className={classNames(classes.root, rootClassName, "ss")} style={style}>
      <Typography
        variant="h6"
        gutterBottom={true}
        className={classes.shareHeading}
      >
        <FormattedMessage
          id="social.shareHeading"
          defaultMessage="Ti piace questo progetto? Aiuta gli altri a trovarlo."
        />
      </Typography>

      <FacebookShareButton
        beforeOnClick={trackShare.bind(null, "facebook")}
        url={
          "https://rifugiamiata.it/?pk_campaign=siteshare&pk_kwd=facebook&pk_source=" +
          sourcePage
        }
        className={classes.ssbtn}
        quote={facebookQuote}
      >
        <FacebookIcon size={32} round={true} />  
      </FacebookShareButton>
      <LinkedinShareButton
        beforeOnClick={trackShare.bind(null, "linkedin")}
        url={
          "https://rifugiamiata.it/?pk_campaign=siteshare&pk_kwd=linkedin&pk_source=" +
          sourcePage
        }
        className={classes.ssbtn}
      >
        <LinkedinIcon size={32} round={true} />  
      </LinkedinShareButton>
      <TwitterShareButton
        beforeOnClick={trackShare.bind(null, "twitter")}
        url={
          "https://rifugiamiata.it/?pk_campaign=siteshare&pk_kwd=twitter&pk_source=" +
          sourcePage
        }
        title={twitterTitle}
        hashtags={[
          "RifugiNaturalisticiAmiata",
          "MonteAmiata",
        ]}
        className={classes.ssbtn}
      >
        <TwitterIcon size={32} round={true} />  
      </TwitterShareButton>
      <WhatsappShareButton
        beforeOnClick={trackShare.bind(null, "WhatsApp")}
        url={
          "https://rifugiamiata.it"
        }
        title={whatsAppTitle}
        className={classes.ssbtn}
      >
        <WhatsappIcon size={32} round={true} />   
      </WhatsappShareButton>   
      <a
        href={emailLink}
        onClick={handleEmailClick}
        className={classes.ssbtn}
      >
        <EmailIcon size={32} round={true} />  
      </a>
    </div>
  );
};

export default withStyles(styles)(injectIntl(Social));
