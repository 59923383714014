import Paper from '@mui/material/Paper';
import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import React, { useRef } from 'react'
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import styles from "./styles";
import dynamic from 'next/dynamic'
import tracking from '../../utils/tracking';
import { useIntl } from "react-intl";

const RifugiList = ({ classes, rifugi, mapbox_key }) => {
  const intl = useIntl();

  rifugi.sort((a, b) => {
    let fa = a.Name.toLowerCase(),
        fb = b.Name.toLowerCase();

    if (fa < fb) {
        return -1;
    }
    if (fa > fb) {
        return 1;
    }
    return 0;
});

  const RifugiContext = React.createContext({
    selectedRifugi: null,
  });
  const defaultColDef = {
    flex: 1,
    sortable: true,
    minWidth: 100,
    resizable: true,
  };
  const [selectedRifugio, setSelectedRifugio] = React.useState(null);
  const gridRef = useRef(null);
  
  const onOptOutClick = (e, name) => {
    tracking.trackClickLink(name);
  };
  
  const NameCellRenderer = params => {
    if (params.data.URL) {
      return (
        <a target='_blank' rel="noreferrer noopener" href={params.data.URL}>{params.data.Name}</a>
      );
    } else {
      return params.data.Name;
    }
  };

  const onQuickFilterChanged = () => {
    const value = document.getElementById('quickFilter').value;
    gridRef.current.api.setQuickFilter(value);
    tracking.trackSearch(value);
  };

  const onFirstDataRendered = (params) => {
    gridRef.current.api.sizeColumnsToFit();
  };

  const onGridSizeChanged = (params) => {
    var gridWidth = document.getElementById('grid-wrapper').offsetWidth;
    var columnsToShow = [];
    var columnsToHide = [];
    var totalColsWidth = 0;
    var allColumns = gridRef.current.columnApi.getAllColumns();
    for (var i = 0; i < allColumns.length; i++) {
      var column = allColumns[i];
      totalColsWidth += column.getMinWidth()+100;
    }

    if (totalColsWidth > gridWidth) {
      gridRef.current.columnApi.setColumnsVisible(["Size"], false);
    } else {
      gridRef.current.columnApi.setColumnsVisible(["Size"], true);
    }
    gridRef.current.api.sizeColumnsToFit();
  };

  const onRowClicked = (e) => {
    setSelectedRifugio(e.data); 
    tracking.trackSelectedRifugio(e.data.Name);
    window.scrollTo(0, 300);
  };
  
  const Map = React.useMemo(() => dynamic(
    () => import('../MainMap'), // replace '@components/map' with your component's location
    { 
      ssr: false // This line is important. It's what prevents server-side render
    }
  ), [selectedRifugio])

  return (
    <div className={classes.container}>
      <Paper className={classes.inner}>
        <Paper elevation={2} className={classes.mapContainer}>
          <Map rifugi={rifugi} selectedRifugio={selectedRifugio} mapbox_key={mapbox_key} />
        </Paper>
        <div className={classes.database}>
          <Paper className={classes.searchRoot} id="grid-wrapper" elevation={1}>
            <SearchIcon />
            <InputBase id="quickFilter" className={classes.searchInput} placeholder="Search..." onInput={() => onQuickFilterChanged()} />
          </Paper>
          <div
            className="ag-theme-material"
            style={{  
              height: '500px',
              width: '100%',
            }}
          >
            <AgGridReact
              ref={gridRef}
              rowData={rifugi}
              rowSelection="single"
              onRowClicked={onRowClicked}
              defaultColDef={{
                flex: 1,
                sortable: true,
                resizable: true,
              }}
              components={{
                nameCellRenderer: NameCellRenderer,
              }}
              onFirstDataRendered={onFirstDataRendered}
              onGridSizeChanged={onGridSizeChanged}
            >
              <AgGridColumn field="Name" headerName={intl.formatMessage({id: "rifugilist.rifugio.name", defaultMessage: "Nome del rifugio"})} minWidth={100} maxWidth={200} cellRenderer="nameCellRenderer" ></AgGridColumn>
              <AgGridColumn field="Size" headerName={intl.formatMessage({id: "rifugilist.rifugio.size", defaultMessage: "Superficie (ettari)"})} minWidth={100} maxWidth={120} ></AgGridColumn>
              <AgGridColumn field="Description" headerName={intl.formatMessage({id: "rifugilist.rifugio.desciption", defaultMessage: "Descrizione"})} minWidth={100} ></AgGridColumn>
            </AgGridReact>                     
          </div>
        </div>                 
      </Paper>
    </div>
  );
}

export default withStyles(styles)(RifugiList);