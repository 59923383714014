import { container } from "../../styles/layout";
import { theme } from "../../styles/theme";

const Theme = (theme) => ({
  hero: {
    backgroundColor: theme.palette.primary.main,
    ...theme,   
  },
  container: {
    maxWidth: "100%",
    position: "relative",
    height: "440px",
    padding: "110px 30px 20px 30px",
    boxSizing: "border-box",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "90px",
      height: "480px",
    }
  },
  heading: {
    maxWidth: "850px !important",
    margin: "25px auto 25px auto",
    [theme.breakpoints.down("xs")]: {
      marginTop: "0px",
      marginBottom: "100px",  
    }    
  },  
  backgroundImage: {
    marginTop: "50px",
    zIndex: "0",
    objectFit: "cover",
    objectPosition: "center",
    [theme.breakpoints.down("sm")]: {
      marginTop: "0px",
    }    
  },
  intro: {
    position: "relative",
    zIndex: "1",
    maxWidth: "800px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    }     
  },
  WhiteText: {
    color: "white"
  },
  introEnd: {
    marginBottom: "50px",
    maxWidth: "530px"
  },
  titleImg: {
    width: "200px",
    maxWidth: "75%"
  },
  title: {
    position: "relative",
    zIndex: "1",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.8rem",
    }      
  },
  subTitle:{
    position: "relative",
    zIndex: "1",    
    [theme.breakpoints.down("md")]: {
      fontSize: "1rem",
    }       
  },
  introLink: {
    position: "relative",
    zIndex: "1",
    color: theme.palette.secondary.main,
    fontWeight: "bold",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline"
    }
  },
  CTAButton: {
    marginTop: "10px",
    marginRight: "20px",
    borderRadius: "24px 24px 24px 24px",
    color: "white",
    fontWeight: "600",
    padding: "10px 20px",
    "&:hover": {
      background: theme.palette.secondary.main,
    },     
  },
});

export default Theme;