import { container } from "../../styles/layout";

export default theme => ({
  container: {
    position: "relative",
    ...container,
    paddingTop: "30px",
    marginTop: "-40px",
    marginBottom: "30px",    
    [theme.breakpoints.down("sm")]: {
      marginTop: "-70px",
    }    

  },
  inner: {
    flexGrow: 1,
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
    paddingBottom: '20px',
  },
  mapContainer: {
    margin: "10px",
  },
  database: {
    paddingLeft: '50px',
    paddingRight: '50px',
    [theme.breakpoints.down("sm")]: {
      paddingLeft: '15px',
      paddingRight: '15px',
    },    
  },
  paper: {
    height: 170,
    width: 170,
    [theme.breakpoints.down("sm")]: {
      height: 170,
      width: 150,
    },
  },
  searchRoot: {
    padding: '2px 4px',
    margin: '15px 40px',
    display: 'flex',
    alignItems: 'center',
    width: 'auto',
    [theme.breakpoints.down("sm")]: {
      marginLeft: '10px',
      marginRight: '10px',
    },        
  }, 
  searchInput: {
    width: '100%',
  },
});
